<template>
    <div class="container mx-auto flex flex-col px-8 mx-4">
        <div class="container px-4 mx-auto flex flex-col justify-between">
            <p class="inline-block cursor-pointer text-xl text-brand uppercase -mt-2">Генерация промокодов</p>
            <div v-if="loaded" class="flex w-full">
                <div v-if="!promoUrl" class="mt-8 w-full">
                    <div class="w-1/2 my-2">
                        <label class="font-semibold text-brand">Город</label>
                        <search-select class="mt-2"
                                v-if="cities.length"
                                v-model="form.city_id"
                                :options="cities"
                                :disabled="is_checked"
                                :filter-function="applySearchFilter"
                        >
                        </search-select>
                        <p v-if="form.errors.has('city_id')"
                           v-text="form.errors.first('city_id')"
                           class="mt-2 text-red text-xs italic"
                        ></p>
                    </div>
                    <div class="w-full flex mt-8">
                        <div class="flex-auto pt-2 pr-2 flex flex-col w-1/4">
                            <label class="font-semibold text-brand">Кол-во промокодов</label>
                            <input class="rounded shadow appearance-none border border-grey w-full mt-2 py-2 px-3 text-grey-darker"
                                   :class="{'border-red': form.errors.has('count')}" v-model="form.count"/>
                            <p v-if="form.errors.has('count')"
                               v-text="form.errors.first('count')"
                               class="mt-2 text-red text-xs italic"
                            ></p>
                        </div>
                        <div class="flex-auto pt-2 pr-2 flex flex-col w-1/4">
                            <label class="font-semibold text-brand">Срок действия (до)</label>
                            <v-date-picker v-model='form.expired_at'
                                    :min-date="new Date()"
                                    class="no-underline rounded shadow appearance-none border border-grey w-full mt-2 py-2 px-3 text-grey-darker"
                                    show-caps>
                            </v-date-picker>
                            <p v-if="form.errors.has('expired_at')"
                               v-text="form.errors.first('expired_at')"
                               class="mt-2 text-red text-xs italic"
                            ></p>
                        </div>
                        <div class="flex-auto pt-2 pr-2 flex flex-col w-1/4">
                            <label class="font-semibold text-brand">Кол-во активаций</label>
                            <input class="rounded shadow appearance-none border border-grey w-full mt-2 py-2 px-3 text-grey-darker"
                                   :class="{'border-red': form.errors.has('total_claims')}" v-model="form.total_claims"/>
                            <p v-if="form.errors.has('total_claim')"
                               v-text="form.errors.first('total_claim')"
                               class="mt-2 text-red text-xs italic"
                            ></p>
                        </div>
                        <div class="flex-auto pt-2 flex flex-col w-1/4">
                            <label class="font-semibold text-brand">Значение скидки</label>
                            <select v-model="form.value"
                                    class="rounded shadow appearance-none border border-grey w-full mt-2 py-2 px-3 text-grey-darker"
                                    :class="{'border-red': form.errors.has('value')}">
                                <option value="30" class="bg-brand rounded text-white hover:bg-brand-dark">30</option>
                                <option value="50" class="bg-brand rounded text-white hover:bg-brand-dark">50</option>
                                <option value="90" class="bg-brand rounded text-white hover:bg-brand-dark">90</option>
                                <option value="100" class="bg-brand rounded text-white hover:bg-brand-dark">100</option>
                            </select>
                            <p v-if="form.errors.has('value')"
                               v-text="form.errors.first('value')"
                               class="mt-2 text-red text-xs italic"
                            ></p>
                        </div>
                    </div>
                    <div class="w-full flex">
                      <div class="pt-1 flex flex-col w-1/4">
                        <label class="font-semibold text-brand pb-1">Тип товара</label>
                        <div class="w-full flex mt-auto">
                          <select v-model="form.purchase_type" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option value="game" class="bg-brand rounded text-white hover:bg-brand-dark">MozgoParty</option>
                            <option value="partystore" class="bg-brand rounded text-white hover:bg-brand-dark">PartyStore</option>
                            <option value="live" class="bg-brand rounded text-white hover:bg-brand-dark">Лайв</option>
                            <option value="portal" class="bg-brand rounded text-white hover:bg-brand-dark">Оффлайн</option>
                          </select>
                        </div>
                        <p v-if="form.errors.has('purchase_type')"
                           v-text="form.errors.first('purchase_type')"
                           class="mt-2 text-red text-xs italic"
                        ></p>
                      </div>
                      <div v-if="form.purchase_type === 'portal'" class="flex w-1/4 self-end pb-2">
                        <city-checkbox  v-model="is_checked" class="ml-4" />
                        <label class="text-grey-darker text-sm font-bold ">
                          Для всех городов
                        </label>
                      </div>
                    </div>
                    <div class="w-full mt-8">
                        <p @click="addit=!addit" class="inline-block cursor-pointer font-semibold text-brand">Доп. условия
                            <font-awesome-icon v-if="addit"
                                               :icon="['fal', 'angle-up']">
                            </font-awesome-icon>
                            <font-awesome-icon v-if="!addit"
                                               :icon="['fal', 'angle-down']"></font-awesome-icon>
                        </p>
                        <div class="w-full" v-if="addit">
                            <div class="flex mt-8" :class="`${form.purchase_type === 'portal'? 'w-1/2' : 'w-full'}`">
                                <div class="flex-auto pr-2 flex flex-col w-1/2">
                                    <label class="font-semibold text-brand">Индивидуальный промокод</label>
                                    <input class="rounded shadow appearance-none border border-grey mt-2 py-2 px-3 text-grey-darker"
                                           :class="{'border-red': form.errors.has('code')}" :disabled="form.count.toString() !== '1'"
                                           v-model="form.code" @keypress="denySymbols()"/>
                                    <p v-if="form.errors.has('code')"
                                       v-text="form.errors.first('code')"
                                       class="mt-2 text-red text-xs italic"
                                    ></p>
                                </div>
                                <div v-if="form.purchase_type !== 'portal'" class="flex-auto flex flex-col w-1/2">
                                    <label class="font-semibold text-brand">Привязка к игре</label>
                                    <search-select class="mt-2"
                                                   v-if="games.length || liveGames.length && form.purchase_type === 'live'"
                                                   v-model="form.game_id"
                                                   :options="searchSelectOptions"
                                                   :filter-function="applySearchFilter"
                                    >
                                    </search-select>
                                    <p v-if="form.errors.has('game_id')"
                                       v-text="form.errors.first('game_id')"
                                       class="mt-2 text-red text-xs italic"
                                    ></p>
                                </div>
                            </div>
                            <div v-if="form.purchase_type === 'portal' && !is_checked" class="w-full mt-8">

                              <div class="flex-auto flex flex-col w-1/2">
                                <label class="font-semibold text-brand">Привязка к продукту</label>
                                <search-select class="mt-2"
                                               v-if="products.length"
                                               v-model="form.product_id"
                                               :options="products"
                                               :filter-function="applySearchFilter"
                                >
                                </search-select>
                                <div class="w-full" v-else>
                                  <input @keypress="denyInput" ref="product" @click.prevent.stop="switchPopper" @mouseleave="switchPopper('show_product_error_popper', false)" class="rounded shadow appearance-none border border-grey mt-2 py-2 text-grey-darker mt-2 w-full"/>
                                </div>
                                <div class="absolute border border-additional-text rounded-lg shadow-lg p-2 bg-white mt-2 w-68" v-if="show_product_error_popper" ref="elements">
                                  <p>Нет продуктов!</p>
                                </div>
                                <p v-if="form.errors.has('product_id')"
                                   v-text="form.errors.first('product_id')"
                                   class="mt-2 text-red text-xs italic"
                                ></p>
                              </div>

                              <div v-if="products.length && series.length" class="flex-auto flex flex-col w-1/2">
                                <label class="font-semibold text-brand">Привязка к серии</label>
                                <search-select class="mt-2"
                                               v-if="series.length"
                                               v-model="form.seria_id"
                                               :options="series"
                                               :filter-function="applySearchFilter"
                                >
                                </search-select>
                                <div class="w-full" v-else>
                                  <input @keypress="denyInput" ref="seria" @click.prevent.stop="switchPopper" @mouseleave="switchPopper('show_seria_error_popper', false)" class="rounded shadow appearance-none border border-grey mt-2 py-2 text-grey-darker mt-2 w-full"/>
                                </div>
                                <div class="absolute border border-additional-text rounded-lg shadow-lg p-2 bg-white mt-2 w-68" v-if="show_seria_error_popper" ref="elements">
                                  <p>Нет игр!</p>
                                </div>
                                <p v-if="form.errors.has('seria_id')"
                                   v-text="form.errors.first('seria_id')"
                                   class="mt-2 text-red text-xs italic"
                                ></p>
                              </div>
                              <div v-if="series.length && eventDays.length" class="mt-4 flex-auto flex flex-col w-1/2">
                                <label class="font-semibold text-brand">Привязка к мероприятию</label>
                                <search-select class="mt-2"
                                               v-if="eventDays.length"
                                               v-model="form.day_id"
                                               :options="eventDays"
                                               :filter-function="applySearchFilter"
                                >
                                </search-select>
                                <p v-if="form.errors.has('day_id')"
                                   v-text="form.errors.first('day_id')"
                                   class="mt-2 text-red text-xs italic"
                                ></p>
                              </div>
                              <p v-if="!form.city_id"
                                 v-text="'Необходимо выбрать город'"
                                 class="mt-2 text-red text-xs italic"
                              ></p>
                            </div>
                            <div class="w-full mt-8">
                                <div class="mb-4 flex">
                                    <city-checkbox v-model="form.is_per_email"
                                                   :element-id="`is_per_email`"></city-checkbox>
                                    <label class="block text-grey-darker text-sm font-bold mb-2">
                                        Один раз на одну почту
                                    </label>
                                </div>
                                <div class="mb-4 flex">
                                    <city-checkbox v-model="form.is_for_new_email"
                                                   :element-id="`is_for_new_email`"></city-checkbox>
                                    <label class="block text-grey-darker text-sm font-bold mb-2">
                                        Только на новую почту
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button href="#" type="submit" @click.prevent.stop="save" :disabled="isDisabled || form.processing"
                            class="bg-brand transition  hover:bg-brand-darker text-white rounded py-2 px-4 mt-8"
                            :class="{'bg-brand-light': isDisabled}">
                        <span v-if="!form.processing">Отправить</span>
                        <span v-else>Идет отправка, подождите</span>
                    </button>
                </div>
                <div v-else class="mt-32 mx-auto">
                    <div class="flex flex-col md:rounded md:shadow h-full md:h-auto">
                        <div class="flex items-center font-bold text-lg text-white bg-brand p-3 justify-center border-brand-darkest font-medium uppercase">
                            Генерация промокодов
                        </div>
                        <div class="bg-white flex-1 p-8">
                            <div class="font-medium text-xl mb-2">Промокоды сгенерированы!</div>
                            <p class="text-grey-darker text-base">
                                Все промокоды доступны по ссылке <br> <a :href="promoUrl" v-text="promoUrl"></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <spinner v-else></spinner>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Form from 'form-backend-validation'
import Spinner from '../../components/spinner'
import SearchSelect from '../question/SearchSelect'
import CityCheckbox from '../city/checkbox'
import Popper from 'popper.js'
import format from 'date-fns/format'
import ru from 'date-fns/locale/ru'

export default {
  components: { CityCheckbox, SearchSelect, Spinner },
  name: 'PromocodesGeneration',
  data () {
    return {
      cities: [],
      games: [],
      mozgoParryGames: [],
      partyStoreGames: [],
      liveGames: [],
      eventRecords: [],
      products: [],
      form: new Form({
        city_id: '',
        count: 1,
        expired_at: new Date(),
        total_claims: 1,
        value: '',
        code: '',
        game_id: '',
        product_id: '',
        seria_id: '',
        day_id: '',
        is_per_email: false,
        is_for_new_email: false,
        purchase_type: '',
        city_id_correct: true,
        is_city_required: true,
      }, { http: axios }),
      promoUrl: '',
      addit: false,
      is_checked:false,
      show_seria_error_popper: false,
      show_product_error_popper: false,
    }
  },
  created () {
    this.getCities()
  },
  methods: {
    getCities () {
      axios.get(`/api/cities/all?sort=name,id`).then((data) => {
        this.cities = data.data.data;
        this.getGames();
        this.getLiveGames();
        this.getSeries();
        this.getProducts();
      })
    },
    getGames () {
      axios.get(`/api/party-games?sort=name,id`).then((data) => {
        this.games = data.data
        this.mozgoParryGames = data.data.filter(obj => obj.system === `MOZGO`)
        this.partyStoreGames = data.data.filter(obj => obj.system === `PARTYSTORE`)
      })
    },
    getLiveGames () {
      const url = window.APP.LIVE_URL || process.env.VUE_APP_LIVE_URL
      axios.get(`${url}/api/live/v1/games`).then((data) => {
        this.liveGames = data.data.items.map(obj => {
          let city = this.cities.find(element => element.id === obj.cityId)
          let playedAt = this.getDate(obj.playedAt.default)
          obj.name = `${obj.name} ${city ? city.name : ''}, ${playedAt}`
          return obj
        })
      })
    },
    getSeries () {
      axios.get(`/api/events/series`).then((data) => {
        this.eventRecords = data.data.data.map(obj => {
          obj.name = `${obj.gameType} ${obj.gameTopic} ${obj.datesRange}`
          obj.id = obj.uuid
          return obj
        })
      })
    },
    getProducts() {
      axios.get(`/api/products?filter[distribution_format_id]=1`).then((data) => {
        this.products = data.data.data;
      })
    },
    save () {
      const url = window.APP.API_PARTY_URL || process.env.VUE_APP_API_PARTY_URL
      this.form.post(`${url}/generate-promo`).then((data) => {
        this.promoUrl = data.url
      })
    },
    applySearchFilter (search, thems) {
      let tempArr = []
      thems.forEach((item) => {
        if (((item.name).toLowerCase()).indexOf(search.toLowerCase()) > -1) tempArr.push((item))
      })
      return tempArr
    },
    denySymbols () {
      let evt = window.event
      let regex = new RegExp('^[abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ23456789_]+$')
      let key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode)
      if (!regex.test(key)) {
        evt.preventDefault()
        return false
      }
    },
    denyInput () {
      let evt = window.event
      evt.preventDefault()
      return false
    },
    switchPopper(type, state = true) {
      switch (type){
        case 'show_seria_error_popper':
          this.show_seria_error_popper = state ? !this.show_seria_error_popper : state;
          break;
        case 'show_product_error_popper':
          this.show_product_error_popper = state ? !this.show_product_error_popper : state;
          break;
      }
      if (this.show_seria_error_popper || this.show_product_error_popper) {
        this.$nextTick(() => {
          this.setupPopper()
        })
      }
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs.seria, this.$refs.elements, {
          placement: 'bottom-end',
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    getDate (date) {
      console.log(date)
      return format(date * 1, 'dd MMMM, HH:mm', {
        locale: ru,
      })
    },
  },
  computed: {
    isDisabled () {
      return !((this.form.city_id || !this.is_city_required) && this.form.count && this.form.value && this.form.expired_at && this.form.total_claims && this.form.purchase_type)
    },
    loaded () {
      return this.cities.length && this.games.length
    },
    series() {
      if (this.products.length > 0 && this.eventRecords.length > 0 && this.form.purchase_type === 'portal' && this.form.product_id) {
        console.log('er', this.eventRecords);
        return this.eventRecords.filter(i => i.city_id.toString() === this.form.city_id.toString() && i.product_id.toString() === this.form.product_id.toString())
      }
      return []
    },
    eventDays() {
      if (this.products.length > 0 && this.series.length > 0 && this.form.purchase_type === 'portal' && this.form.seria_id && this.form.product_id) {
        const seria = this.series.filter(i => i.uuid.toString() === this.form.seria_id.toString());
        if (seria.length > 0)
        return seria[0].eventDays.map(obj => {
          obj.name = `${obj.place} ${obj.played_at}`;
          obj.id = obj.uuid;
          return obj;
        });
      }
      return []
    },
    searchSelectOptions() {
      if (this.form.purchase_type === 'live') {
        return this.liveGames
      } else if (this.form.purchase_type === 'game') {
        return this.mozgoParryGames
      } else if (this.form.purchase_type === 'partystore') {
        return this.partyStoreGames
      }
      return this.games
    }
  },
  watch: {
    'form.purchase_type': {
      handler () {
        this.form.game_id = null
        this.form.product_id = null
        this.form.seria_id = null
        this.form.day_id = null
        this.is_checked = false
      },
      deep: true,
    },
    'form.city_id': {
      handler (val) {
        this.form.product_id = null
        this.form.seria_id = null
        this.form.day_id = null
      },
      deep: true,
    },
    'form.product_id': {
      handler (val) {
        this.form.seria_id = null
        this.form.day_id = null
      },
      deep: true,
    },
    'form.seria_id': {
      handler (val) {
        this.form.day_id = null
      },
      deep: true,
    },
    'is_checked': {
      handler (val) {
        if (this.is_checked){
          this.form.seria_id = ''
          this.form.city_id = ''
          this.form.product_id = ''
          this.form.day_id = ''
          this.form.is_city_required = false
        } else {this.form.is_city_required = true}
      },
      deep: true,
    },

  },
}

</script>

<style scoped>
    @tailwind utilities;
    .router-link-active {
        @apply .bg-brand .text-white
    }
</style>
